import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useState, useEffect } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartDataLabels
);

const DataUI = ({ stateStreamflow }) => {
  //object storing historical data read from json with bottom object layers swapped from [yearRange][postal] to [postal][year]
  const [jsonData, setJsonData] = useState(null);

  //chartjs data objects storing streamflow and temperature data in { labels, datasets } format
  /* labels array contains dates, and datasets array contains data objects for each state */
  const [streamflowData, setStreamflowData] = useState(null);
  const [temperatureData, setTemperatureData] = useState(null);

  //fetch historical data json file and store in jsonData object on first render
  useEffect(() => {
    fetch("/historicData")
      .then((res) => res.json())
      .then((data) => {
        const tempJsonData = {};

        Object.keys(data).forEach((yearRange) => {
          Object.keys(data[yearRange]).forEach((postal) => {
            if (!tempJsonData.hasOwnProperty(postal)) {
              tempJsonData[postal] = {};
            }
            tempJsonData[postal][yearRange.split("-")[2]] = data[yearRange][postal];
          });
        });

        setJsonData(tempJsonData);
      });
  }, []);

  //populate streamflow and temperature data from jsonData once fetched
  /* "Scenario" label is pushed to end of labels array in streamflow data and populated with current streamflow average flow */
  useEffect(() => {
    if (jsonData) {
      const tempStreamflowData = {
        labels: [],
        datasets: [],
      };
      const tempTemperatureData = {
        labels: [],
        datasets: [],
      };

      Object.keys(jsonData).forEach((postal) => {
        const years = Object.keys(jsonData[postal]).sort();

        if (years.length > tempStreamflowData.labels.length) {
          tempStreamflowData.labels = [...years];
          tempTemperatureData.labels = [...years];
        }

        tempStreamflowData.datasets.push({
          label: postal,
          data: years.map((year) => jsonData[postal][year]["avgFlow"]),
          fill: true,
          borderColor: "rgb(207, 207, 207)",
          borderWidth: 1,
          tension: 0,
          segment: {
            borderDash: (ctx) => (ctx.p1DataIndex === years.length ? [6, 6] : undefined),
          },
        });

        tempTemperatureData.datasets.push({
          label: postal,
          data: years.map((year) => jsonData[postal][year]["avgTemp"]),
          fill: true,
          borderColor: "rgb(207, 207, 207)",
          borderWidth: 1,
          tension: 0,
        });
      });

      tempStreamflowData.labels.push("Scenario");
      tempStreamflowData.datasets.forEach((dataset, index, datasets) => {
        datasets[index].data.push(
          stateStreamflow ? stateStreamflow.find((state) => state.postal === dataset.label)["avgFlow"] * 1000000 : 0
        );
      });

      setStreamflowData(tempStreamflowData);
      setTemperatureData(tempTemperatureData);
    }
  }, [jsonData, stateStreamflow]);

  //background gradient object for streamflow
  const backgroundAreaStream = {
    id: "backgroundArea",
    beforeDatasetDraw(chart, args, plugins) {
      const {
        ctx,
        chartArea: { top, bottom, left, right, width, height },
        legend,
        scales: { x, y },
      } = chart;

      var gradient = ctx.createLinearGradient(0, 0, 0, height);
      gradient.addColorStop(1, "rgba(209, 64, 30, 0.25)");
      gradient.addColorStop(0, "rgba(61, 98, 201, 0.4)");

      ctx.save();
      ctx.fillStyle = gradient;
      ctx.fillRect(left, top, width, height);
    },
  };

  //background gradient object for temperature
  const backgroundAreaTemp = {
    id: "backgroundArea",
    beforeDatasetDraw(chart, args, plugins) {
      const {
        ctx,
        chartArea: { top, bottom, left, right, width, height },
        legend,
        scales: { x, y },
      } = chart;

      var gradient = ctx.createLinearGradient(0, 0, 0, height);
      gradient.addColorStop(1, "rgba(61, 98, 201, 0.4)");
      gradient.addColorStop(0, "rgba(209, 64, 30, 0.25)");

      ctx.save();
      ctx.fillStyle = gradient;
      ctx.fillRect(left, top, width, height);
    },
  };

  //chart options
  /* includes unit specifications and postal code labels */
  const chartOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        right: 40,
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            if (0 < value && value < 100) {
              return value + "°F";
            } else {
              return value / 1000000 + "M";
            }
          },
        },
      },
    },
    plugins: {
      datalabels: {
        align: "right",
        formatter: function (value, context) {
          return context.dataset.label;
        },
        display: function (context) {
          return context.dataIndex === context.dataset.data.length - 1;
        },
      },
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    tooltips: { enabled: false },
    hover: { mode: null },
  };

  return (
    <div className="data-ui-container">
      <h1>CURRENT AND HISTORIC AVERAGES</h1>
      {/* <p>Create your own scenario.</p> */}
      {/* <h2>Average Streamflow by Decade</h2> */}
      <h3 className="data-ui-line">
        Upper Basin Average
        <span>
          {stateStreamflow &&
            Math.round(
              stateStreamflow.reduce(
                (partialSum, state) => (state.id < 4 ? partialSum + state.avgFlow : partialSum),
                0.0
              ) * 100
            ) / 100}
          M acre-ft/year
        </span>
      </h3>
      <h3 className="data-ui-line">
        Lower Basin Average
        <span>
          {stateStreamflow &&
            Math.round(
              stateStreamflow.reduce(
                (partialSum, state) => (state.id > 3 ? partialSum + state.avgFlow : partialSum),
                0.0
              ) * 100
            ) / 100}
          M acre-ft/year
        </span>
      </h3>
      <h3 className="data-ui-line">
        Basin Total Average
        <span>
          {stateStreamflow &&
            Math.round(stateStreamflow.reduce((partialSum, state) => partialSum + state.avgFlow, 0.0) * 100) / 100}
          M acre-ft/year
        </span>
      </h3>
      <h2>Streamflow Averages By State</h2>
      <div className="streamflow-chart-container">
        {streamflowData && <Line data={streamflowData} options={chartOptions} plugins={[backgroundAreaStream]} />}
      </div>
      <h2>Temperature Averages By State</h2>
      <div className="streamflow-chart-container">
        {temperatureData && <Line data={temperatureData} options={chartOptions} plugins={[backgroundAreaTemp]} />}
      </div>
    </div>
  );
};

export default DataUI;
