import mapboxgl from "mapbox-gl";
import { useState, useReducer, useRef, useEffect, useCallback } from "react";
import Map, { Source, Layer, Popup } from "react-map-gl";
import GeoRegion from "../streamflow/GeoRegion";
import PopupBox from "./PopupBox";

const Mapbox = ({
  layers,
  mapProps,
  stateStreamflow,
  stateStreamflowDispatch,
  newPopup,
  scenarioFileId,
  datasetNumberTotal,
  setDatasetNumber,
  minSeniority,
  maxSeniority,
}) => {
  const mapRef = useRef(null);

  //dictionary of datasets used for mapbox layers
  const [datasets, datasetsDispatch] = useReducer(datasetsReducer, {});

  //IDs of layers that have info displayed on hover
  const [interactiveLayerIds, setInteractiveLayerIds] = useState([]);

  //true when node and edge datasets for current scenario are loaded
  const [nodesLoaded, setNodesLoaded] = useState(false);
  const [edgesLoaded, setEdgesLoaded] = useState(false);

  //objects storing info for hover and click-based popups
  const [hoverInfo, setHoverInfo] = useState({});
  const [popupInfo, setPopupInfo] = useState(null);

  //filenames for node and edge scenario datasets generated from scenario file ID
  let nodeKey = scenarioFileId && scenarioFileId + "_streamflow_NODES";
  let edgeKey = scenarioFileId && scenarioFileId + "_streamflow_EDGES";

  //return a layer tag nested inside a corresponding source tag for each layer object in layers
  const mapData = layers.map((layer) => {
    if (!layer.source) {
      // source-less layer for background layer or layers whose sources are still loading
      return <Layer {...layer} key={layer.id} />;
    } else {
      // potential ui scalling fix
      /* somewhat hacky way of adding scaling to layer properties ending in -width, -size, or -radius based on
        ratio of user's devicePixelRatio (decent proxy for UI scaling as far as I can tell) to development machine's value of 2.25 */
      if (layer.paint) {
        Object.keys(layer.paint).map((key) => {
          if (key.endsWith("-width") || key.endsWith("-size") || key.endsWith("-radius")) {
            if (layer.paint[key][0] === "interpolate") {
              layer.paint[key][4] = ["*", Math.pow(2.25 / window.devicePixelRatio, 1 / 4), layer.paint[key][4]];
              layer.paint[key][6] = ["*", Math.pow(2.25 / window.devicePixelRatio, 1 / 4), layer.paint[key][6]];
            } else {
              layer.paint[key] = ["*", Math.pow(2.25 / window.devicePixelRatio, 1 / 4), layer.paint[key]];
            }
          }
        });
      }

      // layer with data source and potential hovering filter
      if (layer.metadata.hasOwnProperty("matchProperty")) {
        return (
          <Source
            id={layer.id}
            type="geojson"
            data={datasets[layer.source] ? datasets[layer.source] : null}
            key={layer.id}
          >
            <Layer
              {...layer}
              filter={["==", layer.metadata.matchProperty, hoverInfo.matchProperty ? hoverInfo.matchProperty : ""]}
            />
          </Source>
        );
      } else if (layer.hasOwnProperty("cluster")) {
        return (
          <Source
            id={layer.id}
            type="geojson"
            data={datasets[layer.source] ? datasets[layer.source] : null}
            key={layer.id}
            cluster={layer.hasOwnProperty("cluster") ? layer.cluster : false}
            clusterMaxZoom={layer.hasOwnProperty("clusterMaxZoom") ? layer.clusterMaxZoom : 0}
            clusterRadius={layer.hasOwnProperty("clusterRadius") ? layer.clusterRadius : 0}
            tolerance={layer.hasOwnProperty("tolerance") ? layer.tolerance : 0.375}
            clusterProperties={layer.hasOwnProperty("clusterProperties") ? layer.clusterProperties : {}}
          >
            {
              <Layer
                {...layer}
                id={layer.id + "_clusters"}
                filter={[
                  "all",
                  ["has", "point_count"],
                  [">", ["get", "maximum"], minSeniority],
                  ["<", ["get", "minimum"], maxSeniority],
                ]}
                paint={layer.clusterPaint}
                metadata={{ ...layer.metadata, hoverData: layer.clusterHoverData }}
              />
            }
            <Layer
              {...layer}
              filter={[
                "all",
                ["!", ["has", "point_count"]],
                [">", ["get", "days"], minSeniority],
                ["<", ["get", "days"], maxSeniority],
              ]}
            />
          </Source>
        );
      } else {
        return (
          <Source
            id={layer.id}
            type="geojson"
            data={datasets[layer.source] ? datasets[layer.source] : null}
            key={layer.id}
          >
            <Layer {...layer} />
          </Source>
        );
      }
    }
  });

  //begin fetching datasets from server once map has loaded
  const onLoad = () => {
    layers.forEach((layer) => {
      //load dataset for each layer into datasets object state variable using reducer
      if (layer.source && !datasets.hasOwnProperty(layer.source)) {
        fetch("/datasets/" + layer.source)
          .then((res) => res.json())
          .then((data) => {
            datasetsDispatch({ type: "ADD_DATASET", datasetName: layer.source, dataset: data });
          });
      }
    });
  };

  //initialize streamflow model with scenario dataset
  const initializeModel = (nodeKey, edgeKey) => {
    Mapbox.instance = mapRef?.current;
    Mapbox.instance.setConsumptiveUse = setConsumptiveUse;
    Mapbox.instance.setAllocation = setAllocation;
    Mapbox.instance.getTotalVolume = getTotalVolume;
    Mapbox.instance.getStreamSendAvg = getStreamSendAvg;
    Mapbox.instance.setUniformScalar = setUniformScalar;
    Mapbox.instance.updateMap = initMap;
    Mapbox.instance.sendFlags = sendFlags;
    Mapbox.instance.updateEcoRegionFeatures = updateEcoRegionFeatures;

    GeoRegion.init(datasets[nodeKey], datasets[edgeKey], datasets["ecoregions"]);
    initMap();
    //console.log("initializing: " + nodeKey);

    //load icons used for map features
    Mapbox.instance.loadImage("icons/hatch.png", (error, image) => {
      if (error) throw error;
      if (!Mapbox.instance.hasImage("hatch")) Mapbox.instance.addImage("hatch", image, { sdf: true });
    });
    Mapbox.instance.loadImage("icons/dams.png", (error, image) => {
      if (error) throw error;
      if (!Mapbox.instance.hasImage("dam")) Mapbox.instance.addImage("dam", image, { sdf: true });
    });

    //reset miscelaneous model parameters
    stateStreamflow.forEach((state) => {
      stateStreamflowDispatch({
        type: "SET",
        id: state.id,
        property: "totalVolume",
        value: Mapbox.instance.getTotalVolume(state.postal) / 1000000.0,
      });
      stateStreamflowDispatch({
        type: "SET",
        id: state.id,
        property: "avgFlow",
        value: Mapbox.instance.getStreamSendAvg(state.postal) / 1000000.0,
      });
    });
  };

  //popuplate interactive layer IDs based on wheather layer has hover content specified
  useEffect(() => {
    const interactiveIds = [];
    layers.forEach((layer) => {
      layer.hasOwnProperty("metadata") && layer.metadata.hasOwnProperty("hoverData") && interactiveIds.push(layer.id);
      layer.hasOwnProperty("cluster") && interactiveIds.push(layer.id + "_clusters");
    });
    setInteractiveLayerIds(interactiveIds);
  }, [layers]);

  //pouplate popup with info from state variable
  useEffect(() => {
    setPopupInfo(newPopup);
  }, [newPopup]);

  //load required dataset if necessary when scenario is changed and update boolean state variables on completion
  useEffect(() => {
    if (scenarioFileId) {
      if (!datasets.hasOwnProperty(nodeKey)) {
        fetch("/scenarios/" + nodeKey)
          .then((res) => res.json())
          .then((data) => {
            datasetsDispatch({ type: "ADD_DATASET", datasetName: nodeKey, dataset: data });
            setNodesLoaded(true);
          });
      } else {
        setNodesLoaded(true);
      }
      if (!datasets.hasOwnProperty(edgeKey)) {
        fetch("/scenarios/" + edgeKey)
          .then((res) => res.json())
          .then((data) => {
            datasetsDispatch({ type: "ADD_DATASET", datasetName: edgeKey, dataset: data });
            setEdgesLoaded(true);
          });
      } else {
        setEdgesLoaded(true);
      }
    }
  }, [scenarioFileId]);

  //initialize model once all required datasets are loaded and reset loading booleans
  useEffect(() => {
    if (
      nodesLoaded &&
      edgesLoaded &&
      datasets.hasOwnProperty(nodeKey) &&
      datasets.hasOwnProperty(edgeKey) &&
      datasets.hasOwnProperty("ecoregions") &&
      Object.keys(datasets).length >= datasetNumberTotal
    ) {
      setNodesLoaded(false);
      setEdgesLoaded(false);

      initializeModel(nodeKey, edgeKey);
    }
    //update loading progress
    setDatasetNumber(Object.keys(datasets).length);
  }, [nodesLoaded, edgesLoaded, datasets]);

  //reducer for adding datasets to datasets object state variable
  function datasetsReducer(state, action) {
    switch (action.type) {
      case "ADD_DATASET":
        const tempState = { ...state };
        tempState[action.datasetName] = action.dataset;

        return tempState;

      default:
        throw Error("Unknown Action: " + action.type);
    }
  }

  //sets state consumptive use, updates map, and updates UI object flags
  const setConsumptiveUse = (stateName = "", consumptiveUse = 1.0) => {
    GeoRegion.setConsumptiveUse(stateName, consumptiveUse);
    updateStreamflowFeatures();
    sendFlags();
  };

  //sets state allocation and updates UI object flags
  const setAllocation = (stateName = "", allocation = 1.0) => {
    GeoRegion.setAllocation(stateName, allocation);
    sendFlags();
  };

  const setUniformScalar = (value = 1.0) => {
    GeoRegion.setUniformScalar(value);
    updateStreamflowFeatures();
    sendFlags();
  };

  //gets total volume of a specified state
  const getTotalVolume = (stateName = "") => GeoRegion.getTotalVolume(stateName);

  //get streamflow send average for specified state
  const getStreamSendAvg = (stateName = "") => GeoRegion.getStreamSendAvg(stateName);

  //updates map data based on georegion data
  /* currently there are redundant dataset sources so the same geoergion data may update multiple sources */
  const initMap = () => {
    updateStreamflowFeatures();
    updateEcoRegionFeatures();
  };

  //updates streamflow graph nodes and edge features in mapbox (flags for rerender)
  const updateStreamflowFeatures = () => {
    GeoRegion.updateStreamflowData();

    const nodeSource = Mapbox.instance.getSource(GeoRegion.nodeData.name);
    nodeSource?.setData(GeoRegion.nodeData);

    const edgeSource = Mapbox.instance.getSource(GeoRegion.edgeData.name);
    edgeSource?.setData(GeoRegion.edgeData);

    const damNodeSource = Mapbox.instance.getSource("dams");
    damNodeSource?.setData(GeoRegion.nodeData);
  };

  //updates ecoregion features in mapbox (flags for rerender)
  const updateEcoRegionFeatures = () => {
    GeoRegion.updateEcoregionData();

    const ecoRegionsSource = Mapbox.instance.getSource("ecoregions");
    ecoRegionsSource?.setData(GeoRegion.ecoRegionsData);

    const ecoRegionsHoverSource = Mapbox.instance.getSource("ecoregions_hover");
    ecoRegionsHoverSource?.setData(GeoRegion.ecoRegionsData);
  };

  //gets allocation violation flags and updates UI
  const sendFlags = () => {
    const flags = GeoRegion.getViolationFlags();

    //TODO: send flags to slider objects!
  };

  // on mouse move pull info from what mouse is over to update hover box and hover-based layer filter
  const onHover = useCallback((event, layers) => {
    // pull mapbox feature mouse is currently over
    /* currently pulls the top data layer, but the event stores an ordered array of all the layers currently
    hovered over, so in the future we can determine how to handle overlapping layers more intelligently */
    const hoverFeature = event.features.length && event.features[0];

    let matchProperty = null;

    // generate lines of hover popup content based on layer data template and geoJSON properties
    const hoverContent = [];
    if (hoverFeature) {
      if (hoverFeature.layer?.metadata?.hoverData !== undefined) {
        // fill each hover popup line with text specified in data layer, replacing PROP with specified properties
        // additionally, expressions parameter can be used to specify basic operations to be applied to property value
        hoverFeature.layer.metadata.hoverData.forEach((hoverLine) => {
          // currently supported operations
          const operators = {
            "+": (a, b) => Math.round(a + b),
            "-": (a, b) => Math.round(a - b),
            "*": (a, b) => Math.round(a * b),
            "/": (a, b) => Math.round(a / b),
            conditionalConcat: (a, b) => (a.endsWith(b) ? a : a + b),
            substring: (a, b) => a.substring(0, b),
            list: (a, b) => {
              return a.split(",").join(b);
            },
            commaFormat: (a, b) => Math.round(a, b).toLocaleString(),
            round: (a, b) => {
              if (b === "M") {
                return (Math.round(a / 10000) / 100).toLocaleString() + "M";
              } else if (b === "k") {
                return Math.round(a / 100).toLocaleString() + "k";
              } else {
                return Math.round(a * (10 ^ b)) / (10 ^ b);
              }
            },
          };

          // text line with nth occurance of PROP replaced by nth element of hoverline.properties array, possibly modified by expression
          hoverContent.push(
            <p
              className="hover-text"
              id={
                hoverContent.length === 0
                  ? "hover-bold"
                  : hoverContent.length === hoverFeature.layer.metadata.hoverData.length - 1
                  ? "hover-italics"
                  : ""
              }
              key={hoverLine.properties.length ? hoverLine.properties[0] : hoverLine.text}
            >
              {hoverLine.text
                .split("PROP")
                .map((textChunk, chunkIndex) => {
                  const prop = hoverFeature.properties[hoverLine.properties[chunkIndex]];
                  let modifiedChunk = textChunk;
                  if (
                    chunkIndex < hoverLine.properties.length &&
                    hoverFeature.properties[hoverLine.properties[chunkIndex]] !== undefined
                  ) {
                    if (hoverLine.hasOwnProperty("expressions") && chunkIndex < hoverLine.expressions.length) {
                      modifiedChunk += operators[hoverLine.expressions[chunkIndex][0]](
                        prop,
                        hoverLine.expressions[chunkIndex][1]
                      );
                    } else {
                      modifiedChunk += prop;
                    }
                  }
                  return modifiedChunk;
                })
                .join("")}
            </p>
          );
        });
      }
      //set matchProperty to value from corresponding layer
      if (hoverFeature.layer?.metadata?.hoverLayerId !== undefined) {
        matchProperty = layers.find((layerCheck) => layerCheck.id === hoverFeature.layer.metadata.hoverLayerId).metadata
          .matchProperty;
      }
    }

    // update hoverInfo state variable with current matchProperty id, location, and content to display
    setHoverInfo({
      matchProperty: matchProperty && hoverFeature.properties[matchProperty],
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
      hoverContent: hoverFeature && hoverContent,
    });
  }, []);

  // current click popup window handling. will be reworked soon
  const onPopup = (event, layers) => {
    const popupFeature = event.features && event.features[0];

    const layer = popupFeature && popupFeature.layer;

    if (layer && layer.id === "dams") {
      const stateStreamflowInfo = stateStreamflow.find((state) => state.postal === popupFeature.properties.state);

      setPopupInfo({
        id: layer.id + "-" + popupFeature.properties.id,
        anchor: "left",
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
        offset: 20,
        closeButton: true,
        maxWidth: "25vw",
        title: popupFeature.properties.siteName.endsWith("Dam")
          ? popupFeature.properties.siteName
          : popupFeature.properties.siteName + " Dam",
        subHeader: "",
        body: [
          /*{ type: "image", image: "images/hoover_dam.png", flex: 1 },*/
          {
            type: "text",
            text: "",
            flex: 1,
          },
        ],
        footer: "" /*(
          <div className="popup-slider-container">
            <Slider
              id={9}
              name={"Consumptive Use"}
              use={popupFeature.properties.streamFlow}
              allocation={stateStreamflowInfo.waterRight}
              waterRight={stateStreamflowInfo.waterRight}
              stateStreamflowDispatch={() => {}}
            />
          </div>
        ),*/,
      });
    }
  };

  // map with data layers and hover-based/click-based popups
  return (
    <Map
      ref={mapRef}
      onLoad={onLoad}
      {...mapProps}
      interactiveLayerIds={interactiveLayerIds}
      onMouseMove={(event) => onHover(event, layers)}
      /*onMouseDown={(event) => onPopup(event, layers)}*/
    >
      {mapData}
      {popupInfo && <PopupBox {...popupInfo} onClose={() => setPopupInfo(null)} />}
      {hoverInfo.hoverContent && (
        <Popup
          anchor="left"
          longitude={hoverInfo.longitude}
          latitude={hoverInfo.latitude}
          offset={20}
          closeButton={false}
          closeOnClick={false}
          maxWidth={"15vw"}
          className="hover-popup"
        >
          {hoverInfo.hoverContent}
        </Popup>
      )}
    </Map>
  );
};

Mapbox.instance = mapboxgl.Map.prototype; //this is just for the sake of creating references for objects and methods.
//Mapbox.dynamicLayer = {};

// default map properties
Mapbox.defaultProps = {
  datasets: [],
  layers: [],
  mapProps: {
    mapboxAccessToken:
      "pk.eyJ1IjoiaG93YXJkdGltbGluIiwiYSI6ImNscGlvbnU1dDAxOHQycXFocDBnMGV6cWkifQ.DLJJIVf67ZS1V4eWzxfrNg",
    initialViewState: {
      longitude: -109.575084,
      latitude: 37.488227,
      zoom: 5,
    },
    style: { width: "100%", height: "100%", position: "absolute", top: "0px", left: "0px" },
    mapStyle: "mapbox://styles/howardtimlin/clpiyxrcf00cx01qj8poo91k1",
    projection: "mercator",
    styleDiffing: true,
  },
};

export default Mapbox;
