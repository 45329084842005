import { useState, useEffect } from "react";

const ScenarioUI = ({ setAppState, scenarioFileId, currentStage, prevStageId, nextStageId }) => {
  //scenario data object populated from json in SCENARIOS folder
  const [scenarioData, setScenarioData] = useState(null);

  //fetch scenario data json on scenario file ID change
  useEffect(() => {
    if (scenarioFileId) {
      fetch("/scenarios/" + scenarioFileId)
        .then((res) => res.json())
        .then((data) => {
          setScenarioData(data);
        });
    } else {
      setScenarioData(null);
    }
  }, [scenarioFileId]);

  return (
    scenarioData &&
    scenarioData.stages && (
      <div className="scenario-ui" id={currentStage ? "scenario-ui-collapsed" : null}>
        <div className="scenario-content-container">
          <h1 className="scenario-name">{scenarioData.name}</h1>
          <p className="scenario-description">{scenarioData.stages[currentStage].description}</p>
        </div>
        <div
          className="stages-container"
          id={currentStage ? "stages-container-collapsed" : null}
          style={scenarioFileId === "diy" ? { height: "80%" } : null}
        >
          <div
            className="stage-crumb-trail"
            style={{ height: "calc(100% - " + 100 / scenarioData.stages.length + "%)" }}
          ></div>
          <ul className="stages-list">
            {scenarioData.stages.map((stage, stageIndex) => (
              <li
                className="stage-container"
                key={stage.name}
                style={{ height: 100 / scenarioData.stages.length + "%" }}
              >
                <p className="stage-name">{stage.name}</p>
                <div className={"stage-crumb"} id={stageIndex === currentStage ? "crumb-active" : null}>
                  {stageIndex === currentStage && (
                    <img src="icons/check.png" alt={"Current Scenario Stage"} className="stage-crumb-icon"></img>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
        {/* Jump to Explore Button */}
        {scenarioFileId === "diy" && (
          <div className="scenario-explore-button-container">
            <div
              className="scenario-button"
              onClick={() => {
                setAppState("none");
              }}
            >
              <p className="explore-button-text">Jump to Explore</p>
            </div>
          </div>
        )}
        {/* Next/Previous Scenario Buttons */}
        <div className="scenario-buttons">
          <div
            className="scenario-button"
            id={currentStage ? null : "scenario-button-inactive"}
            onClick={() => {
              prevStageId && setAppState(prevStageId);
            }}
          >
            <img src="icons/arrow_white.png" alt={"Current Scenario Stage"} className="scenario-button-icon"></img>
          </div>
          <div
            className="scenario-button"
            id={nextStageId || scenarioFileId !== "diy" ? null : "scenario-button-inactive"}
            onClick={() => {
              if (nextStageId) {
                setAppState(nextStageId);
              } else if (scenarioFileId !== "diy") {
                setAppState("scenario-" + (parseInt(prevStageId.split("-")[1]) + 1) + "-1");
              }
            }}
          >
            {nextStageId || scenarioFileId === "diy" ? (
              <img
                src="icons/arrow_white.png"
                alt={"Current Scenario Stage"}
                className="scenario-button-icon"
                style={{ transform: "rotate(180deg)" }}
              ></img>
            ) : (
              <p className="explore-button-text">Next Scenario</p>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default ScenarioUI;
