import NodeData from "./NodeData";
import EdgeData from "./EdgeData";

//Node object. Manages node and edge data in context of immediate upstream and downstream neighbors.
class StreamNode {
  nodeData = NodeData;
  edgeData = EdgeData;

  baseStreamSend = 0.0;
  baseStreamRecv = 0.0;
  baseStreamDelta = 0.0; //accounts for base offset between inflows from other nodes and outflows to other nodes
  baseStreamDeltaPos = 0.0; //accounts for base internal contribution. This can potentially be a negative number
  baseStreamDeltaNegs = {};//0.0; //accounts for base internal consumption
  baseStreamDeltaNegWeights = {} //0.0; //converts state consumption total to weighted negative delta
  baseStreamDeltaNegTotal = 0.0;

  crntStreamSend = 0.0;
  crntStreamRecv = 0.0;
  crntStreamDelta = 0.0; //accounts for crnt offset between inflows from other nodes and outflows to other nodes
  crntStreamDeltaNegs = {} //0.0; //accounts for crnt internal consumption
  crntStreamDeltaNegTotal = 0.0;

  //the following properties are for graphic purposes only
  minStreamflow = 0.0;
  maxStreamflow = 11145372.0; //2145372.0 //21145372.0;
  minEdgeWeight = 2.0;
  maxEdgeWeight = 20.0;

  constructor(nodeData = NodeData, edgeData = EdgeData) {
    this.nodeData = nodeData;
    this.edgeData = edgeData;
    
    nodeData.properties.states.forEach(state => {
      this.baseStreamDeltaNegWeights[state] = 0;
    });
  }

  //initializes streamflow delta properties
  initStreamflow(nodes = [StreamNode]) {
    //set streamflow output based on streamflow from dataset
    this.crntStreamSend = this.baseStreamSend = this.nodeData.properties.streamFlow;

    //set streamflow input based on streamflow from immediate upstream nodes in dataset
    this.crntStreamRecv = this.baseStreamRecv = this.getStreamRecv(nodes);

    //reset negative delta to 0 to ensure accuracy on delta initialization
    this.baseStreamDeltaNegTotal = 0;

    //console.log(this.crntStreamSend + " vs. " + this.crntStreamRecv);
    //return initial stream output for state-level total
    return this.baseStreamSend;
  }

  //initializes streamflow delta properties
  initStreamflowWeight(state = "", stateStreamSendTotal = 1.0) {
    //set streamflow delta consumption weight at this based on ratio of initial output over state total.
    //This is an over-simplification, but we are following a model where consumption is even across the state.
    this.baseStreamDeltaNegWeights[state] = this.baseStreamSend / stateStreamSendTotal;

    //console.log("@ initStreamflowWeight: " + this.baseStreamDeltaNegWeights[state]);
  }

  //initializes streamflow delta properties
  initStreamflowDelta(state = "", stateConsumptionTotal = 1.0) {
    //set streamflow delta between input and output. This is the net effect between contribution and consumption at this node.
    this.crntStreamDelta = this.baseStreamDelta = this.baseStreamSend - this.baseStreamRecv;

    //update node streamflow consumption based on proportion of state total consumption
    //accounts for posibility of 2 states
    this.baseStreamDeltaNegs[state] = stateConsumptionTotal * this.baseStreamDeltaNegWeights[state];
    
    //add to base consumption total
    this.baseStreamDeltaNegTotal += this.baseStreamDeltaNegs[state];

    //updates current streamflow consumption to match updated base consumption
    this.crntStreamDeltaNegTotal = this.baseStreamDeltaNegTotal;

    //set node streamflow contribution based on sum of imitial delta and consumption.
    //we presume that the impact of the state's current consumption is already reflected in the data available to us.
    this.baseStreamDeltaPos = this.baseStreamDelta + this.baseStreamDeltaNegTotal;
    // this.baseStreamDeltaPos = this.baseStreamDelta + this.baseStreamDeltaNeg;
  }

  //returns true if this node is an input node for a state or if it is an upstream terminus (internal source)
  isRecvNode(nodes = [StreamNode]) {
    const prevIds = this.nodeData.properties.prevIds;

    if (prevIds.length === 0 || prevIds[0] === this.nodeData.properties.iD) return true;
    else {
      let isRecv = false;
      const prevIds = this.nodeData.properties.prevIds;
      for (let i = 0; i < prevIds.length; i++) {
        isRecv = nodes[prevIds[i]].nodeData.properties.state == this.nodeData.properties.state;

        if (!isRecv) break;
      }

      return !isRecv;
    }
  }

  //returns true if this node is an output node for a state or if it is a downstream terminus (Gulf of California)
  isSendNode(nodes = [StreamNode]) {
    const nextId = this.nodeData.properties.nextId;

    if (nextId < 0 || nextId > nodes.length - 1) {
      return true; //end of the line!
    } else return nodes[this.nodeData.properties.nextId].nodeData.properties.state !== this.nodeData.properties.iD;
  }

  //updates internal streamflow properties based on upstream input and then updates internal feature data
  updateStreamflow(nodes = [StreamNode], scalar = "1.0") {
    //get upstream contribution
    this.crntStreamRecv = this.getStreamRecv(nodes);

    //const prev = this.crntStreamSend;

    //calculate what is passing through based on contribution, base consumption, and current consumption delta
    this.crntStreamSend = this.crntStreamRecv + this.setCrntStreamDelta(scalar); //update positive component of crnt delta based on scalar
    //this.crntStreamSend = this.crntStreamRecv + this.crntStreamDelta;

    //if consumption outstrips output stream, output stream is 0. It's not like the current is going to just suddenly reverse.
    if (this.crntStreamSend < 0) this.crntStreamSend = 0;

    //set data properties
    this.nodeData.properties.streamFlow = this.crntStreamSend;
    this.edgeData.properties.weight = this.mapToLineWeight();
    this.edgeData.properties.color = this.mapToColor();

    //const delta = this.crntStreamDelta - prev;

    //console.log(this.nodeData);

    // if(this.nodeData.properties.iD == 112) 
    // {
    //   console.log('scalar: ' + scalar);
    //   console.log('delta: ' + delta);
    //   console.log('crntStreamSend: ' + this.crntStreamSend);
    //   console.log('crntStreamRecv: ' + this.crntStreamRecv);
      
    //   console.log('baseStreamDeltaPos: ' + this.baseStreamDeltaPos);
    //   console.log('crntStreamDeltaNegTotal: ' + this.crntStreamDeltaNegTotal);
    //   console.log('crntStreamDelta: ' + this.crntStreamDelta);
    // }

    //console.log('update node: ' + this.crntStreamSend);

    /*
    const oldWeight = Number(this.edgeData.properties.weight);
    const newWeight = Number(this.mapToLineWeight());
    //const mult = Math.pow(2, newWeight - oldWeight);
    const mult = 2;

    const pulseLength = 2000;
    let start, previousTimeStamp;
    let done = false;

    const step = (timeStamp) => {
      if (start === undefined) {
        start = timeStamp;
      }
      const elapsed = timeStamp - start;

      if (previousTimeStamp !== timeStamp) {
        const t = elapsed / pulseLength;

        if (t <= 0.5) {
          this.edgeData.properties.weight = 2 * t * mult * newWeight + (1 - 2 * t) * oldWeight;
        } else {
          this.edgeData.properties.weight = (2 * t - 1) * newWeight + (1 - (2 * t - 1)) * mult * newWeight;
        }

        if (elapsed >= pulseLength) done = true;
      }

      //if (this.edgeData.properties.iD === 5) console.log(this.edgeData.properties.weight - newWeight);
      if (elapsed < pulseLength) {
        previousTimeStamp = timeStamp;
        if (!done) {
          window.requestAnimationFrame(step);
        }
      }
    };
    console.log("\n");
    window.requestAnimationFrame(step);
    */
  }

  //use only on initialization and after updating streamFlow property in nodeData for previous nodes
  getStreamRecv(nodes = [StreamNode]) {
    let recv = 0.0;

    this.nodeData.properties.prevIds.forEach((i) => {
      recv += nodes[i].nodeData.properties.streamFlow;
    });

    return recv;
  }

  //returns current weighted consumptive use for the input state
  getWeightedConsumptiveUse(state = ""){
    return this.crntStreamDeltaNegs[state];
  }

  //sets current streamflow consumption
  setStreamConsumption(state = "", stateConsumptionTotal = 0.0, scalar = 1.0) {

    this.crntStreamDeltaNegs[state] = stateConsumptionTotal * this.baseStreamDeltaNegWeights[state];
    
    this.crntStreamDeltaNegTotal = 0.0;

    for(const state in this.crntStreamDeltaNegs)
      this.crntStreamDeltaNegTotal += this.crntStreamDeltaNegs[state];
    
    this.setCrntStreamDelta(scalar);
  }

  //sets current streamflow delta using input scalar.
  setCrntStreamDelta(scalar = 1.0)
  {
    this.crntStreamDelta = (this.baseStreamDeltaPos * scalar) - this.crntStreamDeltaNegTotal;

    return this.crntStreamDelta;
  }

  // setStreamConsumption(stateConsumptionTotal = 0.0) {
  //   this.crntStreamDeltaNeg = stateConsumptionTotal * this.baseStreamDeltaNegWeights;
  //   this.crntStreamDelta = this.baseStreamDeltaPos - this.crntStreamDeltaNeg;
  // }

  //NOTE: further refinement required. Adjustments to streamflow
  //need to be more obvious visually while accomodating smaller non-zero streamflow rates.
  //Suggestion: conditionally set line type for zero streamflow.

  // mapToGraphics() {
  //   const ratio = this.crntStreamSend / this.maxStreamflow;
  //   const scaled = ratio * 10;

  //   let logValue = Math.log2(scaled + 1);

  //       const from0 = 0;
  //   const from1 = 3; //this.maxStreamflow;
  //   const fromRange = from1 - from0;

  //   const to0 = this.minEdgeWeight;
  //   const to1 = this.maxEdgeWeight;
  //   const toRange = to1 - to0;

  //   //if(isNaN(logValue) || logValue < 0) logValue = 0;

  //   let linearValue = ((logValue - from0) * toRange) / fromRange + to0;

  //   return linearValue;
  // }

  // //remaps current output streamflow to graphic weight based on garphic params
  // mapToGraphics() {

  //   const ratio = this.crntStreamSend / this.maxStreamflow;
  //   const pi = Math.PI;

  //   let atan = Math.atan(ratio * pi) / (pi * 0.5);

  //   let value = atan * this.maxEdgeWeight;

  //   if(value < 1)
  //   {
  //     value = 1;

  //     console.log('ratio: ' + ratio + ', atan: ' + atan);
  //   }

  //   // const from0 = this.minStreamflow;
  //   // const from1 = this.maxStreamflow;
  //   // const fromRange = from1 - from0;

  //   // const to0 = this.minEdgeWeight;
  //   // const to1 = this.maxEdgeWeight;
  //   // const toRange = to1 - to0;

  //   // let linearValue = ((this.crntStreamSend - from0) * toRange) / fromRange + to0;

  //   return value;
  // }

  // //remaps current output streamflow to graphic weight based on garphic params
  // mapToGraphics() {
  //   const from0 = this.minStreamflow;
  //   const from1 = 20.0; //this.maxStreamflow;
  //   const fromRange = from1 - from0;

  //   const to0 = this.minEdgeWeight;
  //   const to1 = this.maxEdgeWeight;
  //   const toRange = to1 - to0;

  //   let logValue = Math.log(this.crntStreamSend + 1);
  //   //if(isNaN(logValue) || logValue < 0) logValue = 0;

  //   let linearValue = ((logValue - from0) * toRange) / fromRange + to0;

  //   return logValue;
  // }

  //remaps current output streamflow to graphic weight based on garphic params
  mapToLineWeight() {
    const from0 = this.minStreamflow;
    const from1 = this.maxStreamflow;
    const fromRange = from1 - from0;

    const to0 = this.minEdgeWeight;
    const to1 = this.maxEdgeWeight;
    const toRange = to1 - to0;

    let linearValue = ((this.crntStreamSend - from0) * toRange) / fromRange + to0;

    if (linearValue < to0) linearValue = to0;
    //else if(linearValue > to1) linearValue = to1;

    return linearValue;
  }

  mapToColor() {
    let value = 0;

    if (this.nodeData.properties) {
      value =
        (this.nodeData.properties.streamFlow - this.nodeData.properties.streamFlowAvg) / (this.baseStreamSend + 1);

      value = value + 0.5;
      value = Math.max(Math.min(value, 1), 0);
    }

    /*
    let value = (this.crntStreamSend + 1) / (this.baseStreamSend + 1);

    value = Math.log10(value);

    value = Math.max(-0.15, value);

    // old edge color layer expression
    //"line-color": ["interpolate-hcl", ["linear"], ["get", "color"], -0.15, "#5c1f1f", 0.05, "#1385C3"],
    */

    return value;
  }

  // mapToColor()
  // {
  //   const delta = this.crntStreamSend - this.baseStreamSend;
  //   const normalized = 1.0 + (delta / this.baseStreamSend);

  //   let value = 0.5 + (normalized * 0.5);

  //   if(value < 0) value = 0;
  //   else if(value > 1) value = 1;

  //   return value;
  // }
}

export default StreamNode;
//export default new StreamNode(NodeData, EdgeData);
