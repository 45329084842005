import { useState, useEffect, useLayoutEffect, useRef } from "react";
import ReactSlider from "react-slider";
import useResizeObserver from "@react-hook/resize-observer";
import PropTypes from "prop-types";

//expanded slider width renormailization function
const renormalizeFunc = (x) => Math.pow(Math.log(x + 1), 1 / 2);

const Slider = ({
  id,
  name,
  use,
  allocation,
  waterRight,
  //totalVolume,
  stateStreamflowDispatch,
  expanded,
  propTotal,
  funcPropTotal,
}) => {
  //get css variables
  const thumbWidth =
    1.5 * parseInt(window.getComputedStyle(document.documentElement).getPropertyValue("--slider-height"));
  const transitionTime = parseInt(
    window.getComputedStyle(document.documentElement).getPropertyValue("--transition-time")
  );

  //save initial allocation for marker
  const [initial] = useState({ allocation });

  //target and object for current slider dimensions
  const [sliderTarget, setSliderTarget] = useState();
  const sliderDimensions = useDimensions(sliderTarget);

  //slider container reference
  const sliderContainerRef = useRef(null);

  //remove slider transitions after expansion transition time and reinstate when contracted
  useEffect(() => {
    if (expanded) {
      setTimeout(() => {
        if (sliderContainerRef.current) {
          sliderContainerRef.current.classList.add("expanded");
        }
      }, transitionTime);
    } else {
      if (sliderContainerRef.current) {
        sliderContainerRef.current.classList.remove("expanded");
      }
    }
  }, [expanded, transitionTime]);

  //assemble slider
  /* consists of slider container holding consumptive use slider and info box elements. 
     allocation slider is child of track 01 on the consumptive use slider.
     labels and knobs are children of corresponding slider thumbs and tracks */
  return (
    <div
      className="slider-container"
      id={id}
      ref={(element) => {
        setSliderTarget(element);
        sliderContainerRef.current = element;
      }}
      style={{
        width: expanded * expandedSliderWidth(waterRight, propTotal, funcPropTotal) + !expanded * 100 + "%",
        marginLeft: expanded * expandedSliderPos(id, propTotal, funcPropTotal) + "%",
      }}
    >
      <h3>{name + " "}</h3>
      {/* Info Box Labels */}
      {id === 7 && (
        <div style={{ position: "absolute", width: "100%", height: "100%" }}>
          <div className="slider-info-icon-background"></div>
          <img src="icons/i.png" alt="icon" className="slider-info-icon"></img>
          <p className="slider-info-text">
            Based on the 1922 Colorado River Compact governing the equitable division and apportionment of the use of
            the waters of the Colorado River.
          </p>
          <div className="slider-info-use-line">
            <p className="slider-info-use-label">CONSUMPTIVE USE</p>
          </div>
        </div>
      )}
      {/* Water Right Label */}
      <div className="track-water-right-block" style={id === 8 ? { backgroundColor: "transparent" } : {}}>
        <p className="track-text" style={{ left: "100%" }}>
          {waterRight + "M"}
        </p>
        <div className="track-water-right-line" style={id === 8 ? { backgroundColor: "transparent" } : {}}>
          <p className="track-water-right-label" style={id === 8 ? { color: "transparent" } : {}}>
            WATER RIGHT
          </p>
        </div>
      </div>
      <ReactSlider
        className="slider"
        min={0}
        max={waterRight}
        step={0.01}
        value={use}
        disabled={id === 7 || id === 8}
        thumbClassName={id === 8 ? "thumb-disabled" : "thumb"}
        renderThumb={(props, state) => (
          <div {...props}>
            <img className="thumb-knob-icon" alt={name + "Allocation"} src={"icons/knob.png"}></img>
          </div>
        )}
        trackClassName="track"
        // marks={id < 7 && [Math.min(totalVolume, waterRight)]}
        markClassName="mark-total-volume"
        renderTrack={(props, state) => (
          <div {...props}>
            {/* allocation slider is child of second track on use slider to get proper z-indexing */}
            {state.index ? (
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  width: sliderDimensions?.width + "px",
                }}
              >
                <ReactSlider
                  className="slider"
                  min={0}
                  max={waterRight}
                  step={0.01}
                  marks={id !== 8 && [initial.allocation]}
                  value={allocation}
                  disabled={id === 7 || id === 8}
                  thumbClassName="thumb-allocation"
                  trackClassName="track-allocation"
                  markClassName="mark"
                  renderThumb={(props, state) => (
                    <div {...props}>
                      {/* red bar when use slider passes allocation */}
                      <div
                        className="thumb-allocation-overflow"
                        style={{
                          width: overflowWidth(use, allocation, waterRight, sliderDimensions?.width, thumbWidth) + "px",
                        }}
                      ></div>
                      {/* current allocation number text */}
                      <p className="track-text" style={{ right: 0, marginRight: "5px" }}>
                        {allocation + "M"}
                      </p>
                      {/* allocation slider thumb */}
                      <div className="thumb-allocation-line" style={id === 8 ? { height: "100%" } : null}>
                        <div className="thumb-allocation-knob" style={id === 8 ? { display: "none" } : null}>
                          <img className="thumb-knob-icon" alt={name + "Allocation"} src={"icons/knob.png"}></img>
                          <p className="thumb-allocation-label">ALLOCATION</p>
                        </div>
                      </div>
                      {use > allocation && (
                        <img
                          className={"allocation-warning-icon"}
                          src={"icons/warning_red.png"}
                          alt={"Allocation Surpassed"}
                        ></img>
                      )}
                    </div>
                  )}
                  onChange={(value) => {
                    /* update new allocation in data object */
                    stateStreamflowDispatch({ type: "SET", id: id, property: "allocation", value: value });
                  }}
                  onAfterChange={(value) => {
                    /* update model allocation on slider mouse up */
                    stateStreamflowDispatch({ type: "SET", id: id, property: "allocation_model", value: value });
                  }}
                />
              </div>
            ) : (
              <p className="track-text" style={{ left: 0 }}>
                {state.value + "M"}
              </p>
            )}
          </div>
        )}
        onChange={(value) => {
          /* update new use rate in data object */
          stateStreamflowDispatch({ type: "SET", id: id, property: "use", value: value });
        }}
        onAfterChange={(value) => {
          /* update model consumptive use on slider mouse up */
          stateStreamflowDispatch({ type: "SET", id: id, property: "use_model", value: value });
        }}
      />
    </div>
  );
};

//update dimensions of target element using resize observer
function useDimensions(target) {
  const [dimensions, setDimensions] = useState();

  useLayoutEffect(() => {
    target && setDimensions(target.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry) => setDimensions(entry.contentRect));
  return dimensions;
}

//compute consumptive screen width of consumptive use overflow beyond allocation
function overflowWidth(use, allocation, waterRight, width, thumbWidth) {
  return Math.max(0, ((use - allocation) / waterRight) * (width - thumbWidth / 2));
}

//compute screen width of slider given water right
/* water right is assume to be the largest possible value of the slider and therefore
     its "width" in slider units */
function expandedSliderWidth(waterRight, propTotal, funcPropTotal) {
  return (
    (100 * renormalizeFunc(waterRight) * (propTotal("use") / propTotal("waterRight"))) /
    funcPropTotal("use", "waterRight", renormalizeFunc)
  );
}

//compute screen position of left edge of slider
function expandedSliderPos(id, propTotal, funcPropTotal) {
  return (
    (100 * funcPropTotal("use", "waterRight", renormalizeFunc, id) * (propTotal("use") / propTotal("waterRight"))) /
    funcPropTotal("use", "waterRight", renormalizeFunc)
  );
}

Slider.defaultProps = {
  id: 7,
  name: "",
  use: 1.14,
  allocation: 1.54,
  waterRight: 2.14,
  expanded: false,
  propTotal: () => 0,
  funcPropTotal: () => 0,
};

Slider.propTypes = {
  id: PropTypes.oneOf([...Array(10).keys()]),
  name: PropTypes.string,
  use: PropTypes.number,
  allocaiton: PropTypes.number,
  waterRight: PropTypes.number,
  expanded: PropTypes.bool,
  propTotal: PropTypes.func,
  funcPropTotal: PropTypes.func,
};

export default Slider;
