//Data object for streawmflow graph nodes.
const NodeData = {
  "properties": {
    "siteNo": "",
    "siteName": "",
    "category": "",
    "agency": "",
    "longDD": 0.0,
    "latDD": 0.0,
    "siteUrl": "",
    "iD": 0,
    "nextId": 0,
    "prevIds": [
      0
    ],
    "streamFlow": 0.0,
    "streamflowType": 0, //0 = station, 1 = dam, 2 = confluence
    "states": [], //state postal designations as two-char strings
    "color": "white",
    "size": "5"
  },
  "geometry": {
    "coordinates": [
      0.0,
      0.0
    ],
    "type": "Point"
  },
  "type": "Feature"
};

export default NodeData;