import { useState, useEffect, useReducer, useRef } from "react";
import Mapbox from "./components/Mapbox";
import LeftUI from "./components/LeftUI";
import LayersUI from "./components/LayersUI";
import ScenarioUI from "./components/ScenarioUI";
import Header from "./components/Header";
import MenuButton from "./components/MenuButton";
import Modal from "./components/Modal";

/* EXTERNAL CODE SOURCES AND LICENCES */

/*

react-map-gl https://github.com/visgl/react-map-gl?tab=License-1-ov-file
react-slider https://github.com/zillow/react-slider?tab=MIT-1-ov-file
resize-observer from react-hook https://github.com/jaredLunde/react-hook?tab=MIT-1-ov-file
chart.js https://github.com/chartjs/Chart.js?tab=MIT-1-ov-file
react-chartjs-2 https://github.com/reactchartjs/react-chartjs-2?tab=MIT-2-ov-file

*/

//map properties
const mapProps = {
  mapboxAccessToken: "pk.eyJ1IjoiaG93YXJkdGltbGluIiwiYSI6ImNscGlvbnU1dDAxOHQycXFocDBnMGV6cWkifQ.DLJJIVf67ZS1V4eWzxfrNg",
  initialViewState: {
    longitude: -109.575084,
    latitude: 37.488227,
    zoom: 5.75,
  },
  style: { width: "100vw", height: "100vh", position: "absolute", top: "0px", left: "0px" },
  mapStyle: "mapbox://styles/howardtimlin/clpiyxrcf00cx01qj8poo91k1",
  projection: "mercator",
  styleDiffing: true,
  dragRotate: false,
  minZoom: 3,
  maxBounds: [
    [-140, 25],
    [-85, 50],
  ],
};

//number of unique dataset "name" properties from geojson/json files for loading progress
const datasetNumberTotal = 21;

function App() {
  //state variable for array of layer objects
  /* Each layer object holds a list of features from a specified dataset source along with
  the styling information specifying how those features should be displayed. */
  const [layers, layersDispatch] = useReducer(layersReducer, []);

  //state variable for array of state-level streamflow data
  /* Each state streamflow object contains parameters specifying a state id number,
  consumptive use, allocation, and water right. This is what sliders pull their configuration
  from and send updates to when they are modified. */
  const [stateStreamflow, stateStreamflowDispatch] = useReducer(stateStreamflowReducer, []);

  //state variable for uniform streamflow scalar
  const [uniformScalar, setUniformScalar] = useState(1.0);

  //state variables for array of app states and associated variables
  const [appStates, setAppStates] = useState([]);
  const [currentAppStateIndex, setCurrentAppStateIndex] = useState(1);
  const [viewState, setViewState] = useState(mapProps.initialViewState);
  const [newPopup, setNewPopup] = useState(null);
  const [leftUIState, setLeftUIState] = useState(0);
  const [layersUIState, setLayersUIState] = useState(false);

  //load progress state variable for loading cover graphic
  const [loadProgress, setLoadProgress] = useState(0);

  //layers UI ref for show/hide state
  const layersUIRef = useRef(null);

  const [minSeniority, setMinSeniority] = useState(0);
  const [maxSeniority, setMaxSeniority] = useState(65000);

  //handle app state changes by updating associated state variables and resetting model parameters
  const updateAppState = (appStateId) => {
    const appStateIndex = appStates.findIndex((testState) => testState.id === appStateId);
    if (appStateIndex !== -1) {
      setCurrentAppStateIndex(appStateIndex);

      const newState = appStates[appStateIndex];

      //update state variables

      if (newState.LayersUI.visibleLayers) {
        layers.forEach((layer) => {
          if (layer.hasOwnProperty("metadata")) {
            if (newState.LayersUI.visibleLayers.includes(layer.metadata.name)) {
              layersDispatch({
                type: "SET",
                id: layer.id,
                property: "layout",
                subProperty: "visibility",
                value: "visible",
              });
            } else {
              layersDispatch({
                type: "SET",
                id: layer.id,
                property: "layout",
                subProperty: "visibility",
                value: "none",
              });
            }
          }
        });
      }

      if (newState.LayersUI.state) {
        if (layersUIState === !newState.LayersUI.state) {
          layersUIRef.current.classList.toggle("open");
          setLayersUIState(!!newState.LayersUI.state);
        }
      } else {
        if (layersUIState) {
          layersUIRef.current.classList.toggle("open");
        }
        setLayersUIState(false);
      }

      if (newState.Mapbox.startingViewState) {
        setViewState(newState.Mapbox.startingViewState);
      }

      if (newState.Mapbox.PopupBox) {
        setNewPopup(newState.Mapbox.PopupBox);
      } else {
        setNewPopup(null);
      }

      if (newState.LeftUI.state) {
        setLeftUIState(newState.LeftUI.state);
      } else {
        setLeftUIState(0);
      }

      //reset model parameters
      fetch("/stateStreamflow")
        .then((res) => res.json())
        .then((data) => stateStreamflowDispatch({ type: "INITIALIZE", value: data }))
        .then(() => {
          stateStreamflow.forEach((state) => {
            stateStreamflowDispatch({
              type: "SET",
              id: state.id,
              property: "totalVolume",
              value: Mapbox.instance.getTotalVolume(state.postal) / 1000000.0,
            });
            stateStreamflowDispatch({
              type: "SET",
              id: state.id,
              property: "avgFlow",
              value: Mapbox.instance.getStreamSendAvg(state.postal) / 1000000.0,
            });
          });
        });

      setUniformScalar(1.0);
    }
  };

  //fetch list of layer objects and initial state streamflow data on first render
  useEffect(() => {
    fetch("/layers")
      .then((res) => res.json())
      .then((data) => layersDispatch({ type: "INITIALIZE", value: data }));

    fetch("/appStates")
      .then((res) => res.json())
      .then((data) => setAppStates(data));

    fetch("/stateStreamflow")
      .then((res) => res.json())
      .then((data) => stateStreamflowDispatch({ type: "INITIALIZE", value: data }));

    //console.log("devicePixelRatio: " + window.devicePixelRatio);
  }, []);

  //update model uniform scalar to match state variable
  useEffect(() => {
    if (Mapbox.instance.setUniformScalar) {
      Mapbox.instance.setUniformScalar(uniformScalar);
    }
  }, [uniformScalar]);

  //update average streamflow for historical data UI when the UI is opened
  useEffect(() => {
    if (leftUIState === 3) {
      stateStreamflow.forEach((state) => {
        /*
        stateStreamflowDispatch({
          type: "SET",
          id: state.id,
          property: "totalVolume",
          value: Mapbox.instance.getTotalVolume(state.postal) / 1000000.0,
        });
        */
        stateStreamflowDispatch({
          type: "SET",
          id: state.id,
          property: "avgFlow",
          value: Mapbox.instance.getStreamSendAvg(state.postal) / 1000000.0,
        });
      });
    }
  }, [leftUIState]);

  return (
    <div className="App">
      <Mapbox
        layers={layers}
        mapProps={{ ...mapProps, ...viewState, onMove: (evt) => setViewState(evt.viewState) }}
        stateStreamflow={stateStreamflow}
        stateStreamflowDispatch={stateStreamflowDispatch}
        {...(appStates.length && appStates[currentAppStateIndex].Mapbox)}
        newPopup={newPopup}
        scenarioFileId={
          appStates.length &&
          (appStates[currentAppStateIndex].ScenarioUI !== undefined
            ? appStates[currentAppStateIndex].ScenarioUI?.scenarioFileId
            : "diy")
        }
        datasetNumberTotal={datasetNumberTotal}
        setDatasetNumber={(num) => setLoadProgress(num / datasetNumberTotal)}
        uniformScalar={uniformScalar}
        minSeniority={minSeniority}
        maxSeniority={maxSeniority}
      />
      <LeftUI
        stateStreamflow={stateStreamflow}
        stateStreamflowDispatch={stateStreamflowDispatch}
        leftUIState={leftUIState}
        setLeftUIState={setLeftUIState}
        uniformScalar={uniformScalar}
        setUniformScalar={setUniformScalar}
        afterSetUniformScalar={Mapbox.instance.updateEcoRegionFeatures}
      />
      <LayersUI
        visible={layersUIState}
        layers={layers}
        layersDispatch={layersDispatch}
        layersUIRef={layersUIRef}
        updateEcoRegionFeatures={Mapbox.instance.updateEcoRegionFeatures}
        minSeniority={minSeniority}
        setMinSeniority={setMinSeniority}
        maxSeniority={maxSeniority}
        setMaxSeniority={setMaxSeniority}
      />
      {appStates.length && appStates[currentAppStateIndex].ScenarioUI && (
        <ScenarioUI setAppState={updateAppState} {...appStates[currentAppStateIndex].ScenarioUI} />
      )}
      <Header appStateId={appStates.length && appStates[currentAppStateIndex].id} setAppState={updateAppState} />
      <div className="button-panel-right">
        <MenuButton
          id={"layerMenuButton"}
          iconSrcs={["icons/bullet-list_black.png", "icons/bullet-list_white.png"]}
          borderCode={0}
          clicked={layersUIState}
          onClick={() => {
            layersUIRef.current.classList.toggle("open");
            setLayersUIState(!layersUIState);
          }}
        />
        <div className="button-panel-gap"></div>
        <MenuButton
          id={"homeButton"}
          iconSrcs={["icons/home_black.png"]}
          borderCode={1}
          clicked={false}
          onClick={() => {
            updateAppState("none");
          }}
        />
        <MenuButton
          id={"zoomInButton"}
          iconSrcs={["icons/plus_black.png"]}
          borderCode={2}
          clicked={false}
          onClick={() => {
            const tempViewState = { ...viewState };
            tempViewState.zoom = Math.min(tempViewState.zoom + 1, 24);
            setViewState(tempViewState);
          }}
        />
        <MenuButton
          id={"zoomOutButton"}
          iconSrcs={["icons/minus_black.png"]}
          borderCode={3}
          clicked={false}
          onClick={() => {
            const tempViewState = { ...viewState };
            tempViewState.zoom = Math.max(tempViewState.zoom - 1, 0);
            setViewState(tempViewState);
          }}
        />
      </div>
      {appStates[currentAppStateIndex]?.hasOwnProperty("Modals") &&
        appStates[currentAppStateIndex].Modals.map((modalObject, index) => (
          <Modal
            {...modalObject}
            appStateId={appStates[currentAppStateIndex]?.id}
            key={appStates[currentAppStateIndex] + "-modal-" + index}
            hide={
              (leftUIState === 2 && appStates[currentAppStateIndex]?.id === "scenario-5-5") ||
              (leftUIState !== 2 && appStates[currentAppStateIndex]?.id === "scenario-5-7") ||
              (leftUIState !== 2 && appStates[currentAppStateIndex]?.id === "scenario-5-8")
            }
          />
        ))}
      <div
        className="load-cover"
        style={{ opacity: loadProgress < 1 ? 1 : 0, visibility: loadProgress < 1 ? "visible" : "hidden" }}
      >
        <div
          className="load-cover-progress"
          style={{
            height: loadProgress * 100 + "%",
            opacity: loadProgress < 1 ? 1 : 0,
            visibility: loadProgress < 1 ? "visible" : "hidden",
          }}
        ></div>
      </div>
    </div>
  );
}

//reducer for dynamically updating state streamflow data
function stateStreamflowReducer(state, action) {
  switch (action.type) {
    case "SET":
      const tempState = [...state];
      let index = 0;

      if (action.hasOwnProperty("id")) {
        index = tempState.findIndex((obj) => obj.id === action.id);
      } else {
        index = tempState.findIndex((obj) => obj.postal === action.postal);
      }

      // update streamflow model parameters when sliders are modified
      if (action.property === "use") {
        tempState[index][action.property] = action.value;
        Mapbox.instance.setConsumptiveUse(tempState[index].postal, action.value * 1000000.0);
        //Mapbox.instance.updateEcoregions();

        //tempState[index].totalVolume = Mapbox.instance.getTotalVolume(tempState[index].postal) / 1000000.0;
      } else if (action.property === "use_model") {
        //update model consumptive use
        Mapbox.instance.setConsumptiveUse(tempState[index].postal, action.value * 1000000.0);
        Mapbox.instance.updateEcoRegionFeatures();

        //console.log("use_model");
      } else if (action.property === "allocation") {
        tempState[index][action.property] = action.value;

        //Mapbox.instance.setAllocation(tempState[index].postal, action.value * 1000000.0);
      } else if (action.property === "allocation_model") {
        //update model allocation
        Mapbox.instance.setAllocation(tempState[index].postal, action.value * 1000000.0);
        //Mapbox.instance.updateEcoregions();
      } else if (action.property === "totalVolume") {
        tempState[index][action.property] = action.value;
      } else if (action.property === "avgFlow") {
        tempState[index][action.property] = action.value;
      } else {
        throw Error("Unknown or Unsettable Property: " + action.property);
      }

      return tempState;

    case "INITIALIZE":
      action.value.forEach((state) => {
        Mapbox.instance.setConsumptiveUse && Mapbox.instance.setConsumptiveUse(state.postal, state.use * 1000000.0);
        Mapbox.instance.setAllocation && Mapbox.instance.setAllocation(state.postal, state.allocation * 1000000.0);
      });

      return [...action.value];

    default:
      throw Error("Unknown Action: " + action.type);
  }
}

//reducer for dynamically changing layer properties
function layersReducer(state, action) {
  switch (action.type) {
    case "SET":
      const tempState = [...state];
      const index = tempState.findIndex((obj) => obj.id === action.id);

      if (action.subProperty) {
        const tempProperty = { ...tempState[index][action.property] };
        tempProperty[action.subProperty] = action.value;
        tempState[index][action.property] = tempProperty;
      } else {
        state[index][action.property] = action.value;
      }

      return tempState;

    case "INITIALIZE":
      return [...action.value];

    default:
      throw Error("Unknown Action: " + action.type);
  }
}

export default App;
