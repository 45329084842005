//import State from "./State";
//import NodeDataCol from "../data/STREAMFLOW/ordered_streamflow_2023_20240107_NODES";
//import EdgeDataCol from "../data/STREAMFLOW/ordered_streamflow_2023_20240107_EDGES";
import GeoState from "./GeoState";
import StreamNode from "./StreamNode";

class GeoRegion {
  // static nodeData = NodeDataCol;
  // static edgeData = EdgeDataCol;
  static nodeData = {};
  static edgeData = {};
  static ecoRegionsData = {};

  static states = {
    UT: GeoState,
    CO: GeoState,
    WY: GeoState,
    NM: GeoState,
    AZ: GeoState,
    NV: GeoState,
    CA: GeoState,
    BC: GeoState,
  };

  static nodes = [StreamNode];

  static uniformScalar = 1.0;
  //static minStreamFlow = 0.0;
  //static maxStreamFlow = 3.0;
  static minEdgeWeight = 1.0;
  static maxEdgeWeight = 20.0;

  static init(nodeDataCol, edgeDataCol, ecoRegionsData) {
    //console.log("INIT GEOREGION");

    GeoRegion.nodeData = nodeDataCol;
    GeoRegion.edgeData = edgeDataCol;
    GeoRegion.ecoRegionsData = ecoRegionsData;

    GeoRegion.initNodes();
    GeoRegion.initStates();
    GeoRegion.updateStreamflowModel();
    //GeoRegion.update();
  }

  //initialize nodes from dataset
  static initNodes() {
    GeoRegion.nodes = [];

    const nodeData = GeoRegion.nodeData.features;
    const edgeData = GeoRegion.edgeData.features;

    let max = 0.0;
    let logMax = 0.0;
    for (let i = 0; i < nodeData.length; i++) {
      GeoRegion.nodes.push(new StreamNode(nodeData[i], edgeData[i]));

      const v = nodeData[i].properties.streamFlow;
      const lV = Math.log(v);

      if (v > max) max = v;

      if (lV > logMax) logMax = lV;
    }

    //console.log("streamflow max: " + max);
    //console.log("log streamflow max: " + logMax);
  }

  //initialize states from nodes
  static initStates() {
    //console.log("INIT STATES")

    //create states
    GeoRegion.states = {
      UT: new GeoState(),
      CO: new GeoState(),
      WY: new GeoState(),
      NM: new GeoState(),
      AZ: new GeoState(),
      NV: new GeoState(),
      CA: new GeoState(),
      BC: new GeoState(),
    };

    //console.log("POPULATE STATES");

    //populate states
    GeoRegion.nodes.forEach((node) => {
      const stateNames = node.nodeData.properties.states;

      stateNames.forEach((stateName) => {
        if (GeoRegion.states.hasOwnProperty(stateName)) GeoRegion.states[stateName].addNode(GeoRegion.nodes, node);
        else console.warn("GeoRegion states object missing: " + stateName);
      });
    });

    //console.log("GET LOCAL VARS");

    const uT = GeoRegion.states.UT;
    const cO = GeoRegion.states.CO;
    const wY = GeoRegion.states.WY;
    const nM = GeoRegion.states.NM;
    const aZ = GeoRegion.states.AZ;
    const nV = GeoRegion.states.NV;
    const cA = GeoRegion.states.CA;
    const bC = GeoRegion.states.BC;

    //console.log("INIT STATE PROPS");

    // //init state parameters
    uT.init("UT", [aZ, nV, cA, bC], 1300000.0, 1500000.0); //
    cO.init("CO", [nM, uT, aZ, nV, cA, bC], 2500000.0, 3200000.0); //
    wY.init("WY", [cO, uT, aZ, nV, cA, bC], 500000.0, 800000.0); //
    nM.init("NM", [uT, aZ, nV, cA, bC], 500000.0, 600000.0); //
    aZ.init("AZ", [uT, nV, cA, bC], 2000000.0, 2400000.0); //
    nV.init("NV", [aZ, cA, bC], 200000.0, 250000.0); //
    cA.init("CA", [aZ, bC], 3200000.0, 3700000.0); //
    bC.init("BC", [], 0.0, 999999999999.0); //we don't have a way of accounting for this in our model

    //console.log("STATE NODE STREAMFLOWS");

    //initialize receive and send  props
    for (const name in this.states) this.states[name].initStreamflows(GeoRegion.nodes);

    //console.log("STATE NODE STREAMFLOWS WEIGHTS");

    //initialize consumption impact weights
    for (const name in this.states) this.states[name].initStreamflowWeights();

    //console.log("STATE NODE STREAMFLOW DELTAS");

    //initialize consumption and apparent addition
    for (const name in this.states) this.states[name].initStreamflowDeltas();
  }

  //set consumptive use for a state, update the overall model, and update features
  static setConsumptiveUse(stateName = "", consumptiveUse = 1.0) {
    GeoRegion.states[stateName].setConsumptiveUse(consumptiveUse, GeoRegion.uniformScalar);
    GeoRegion.updateStreamflowModel();
  }

  //set allocation for a state
  static setAllocation(stateName = "", allocation = 1.0) {
    GeoRegion.states[stateName].setAllocation(allocation);
  }

  //get total volume for a state
  static getTotalVolume(stateName = "") {
    return GeoRegion.states[stateName].getStreamRecvTotal();
  }

  //sets uniform streamflow scalar and updates streamflow values accordingly
  static setUniformScalar(value = 1.0) {
    GeoRegion.uniformScalar = value;
    GeoRegion.updateStreamflowModel();
  }

  //updates streamflow model
  static updateStreamflowModel() {
    GeoRegion.nodes.forEach((node) => {
      node.updateStreamflow(GeoRegion.nodes, this.uniformScalar);
    });
  }

  //returns average streamflow for input state
  static getStreamSendAvg(stateName = "") {
    return GeoRegion.states[stateName].getStreamSendAvg();
  }

  //updates map data based on node data -> not sure if this is necessary.
  static updateStreamflowData() {
    GeoRegion.nodes.forEach((node) => {
      const nP = node.nodeData.properties;
      const eP = node.edgeData.properties;

      //set current streamflow
      GeoRegion.nodeData.features[nP.iD].properties.streamFlow = nP.streamFlow;

      //set current edge weight
      GeoRegion.edgeData.features[nP.iD].properties.weight = eP.weight;
    });
  }

  //updates ecoregion color based on intersecting associated node/edge color
  static updateEcoregionData() {
    const geoRegionNodeCount = new Array(GeoRegion.ecoRegionsData.features.length);
    GeoRegion.nodes.forEach((node) => {
      const nP = node.nodeData.properties;
      const eP = node.edgeData.properties;

      //update ecoregions
      GeoRegion.nodeData.features[nP.iD].properties.ecoRegions.forEach((regionIndex) => {
        if (!geoRegionNodeCount[regionIndex]) {
          GeoRegion.ecoRegionsData.features[regionIndex].properties.color = eP.color;
          geoRegionNodeCount[regionIndex] = 1;
        } else {
          geoRegionNodeCount[regionIndex] += 1;

          const currentColor = GeoRegion.ecoRegionsData.features[regionIndex].properties.color;
          const currentWeight = (geoRegionNodeCount[regionIndex] - 1) / geoRegionNodeCount[regionIndex];
          const newWeight = 1 / geoRegionNodeCount[regionIndex];

          GeoRegion.ecoRegionsData.features[regionIndex].properties.color =
            currentColor * currentWeight + eP.color * newWeight;
        }
      });
    });
  }

  //returns allocation violation flags for all states
  static getViolationFlags() {
    //send violation flag => Mapbox => LeftUI => Slider
    return {
      UT: GeoRegion.states.UT.getViolationFlag(),
      CO: GeoRegion.states.CO.getViolationFlag(),
      WY: GeoRegion.states.WY.getViolationFlag(),
      NM: GeoRegion.states.NM.getViolationFlag(),
      AZ: GeoRegion.states.AZ.getViolationFlag(),
      NV: GeoRegion.states.NV.getViolationFlag(),
      CA: GeoRegion.states.CA.getViolationFlag(),
      BC: GeoRegion.states.BC.getViolationFlag(),
    };
  }

  //from https://stackoverflow.com/a/57111384
  static isSameType = (a, b) => Object.keys(a).every((k) => k in b);

  // static compareOrder(nodeA, nodeB)
  // {
  //    return nodeA.nodeData.properties.iD - nodeB.nodeData.properties.iD;
  // }
}

//const singleInstance = new GeoRegion();
//GeoRegion.init(NodeDataCol, EdgeDataCol);

export default GeoRegion;
