const MenuButton = ({ id, iconSrcs, borderCode, clicked, onClick }) => {
  let borderStyle = {};

  //set border style based on borderCode
  /*
  0 = isolated button
  1 = top of button cluster
  2 = middle of button cluster
  3 = bottom of button cluster
  */
  switch (borderCode) {
    case 1:
      borderStyle = { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 };
      break;
    case 2:
      borderStyle = {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      };
      break;
    case 3:
      borderStyle = { borderTopLeftRadius: 0, borderTopRightRadius: 0 };
      break;
    default:
      break;
  }

  return (
    <div
      className="menu-button"
      id={clicked ? "menu-button-clicked" : null}
      onClick={() => {
        onClick();
      }}
      style={borderStyle}
    >
      <img
        src={clicked && iconSrcs.length > 1 ? iconSrcs[1] : iconSrcs[0]}
        alt={id + " menu button"}
        className="menu-button-icon"
      ></img>
    </div>
  );
};

export default MenuButton;
