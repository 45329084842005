import { useState, useRef, useEffect } from "react";
import Slider from "./Slider";
import DataUI from "./DataUI";
import About from "./About";
import MenuButton from "./MenuButton";
import ReactSlider from "react-slider";

const LeftUI = ({
  stateStreamflow,
  stateStreamflowDispatch,
  leftUIState,
  setLeftUIState,
  uniformScalar,
  setUniformScalar,
  afterSetUniformScalar
}) => {
  //left UI reference for toggling expanded class
  const leftUIRef = useRef(null);

  //state variable for left UI state
  /*
  0 = closed
  1 = slider UI collapsed
  2 = slider UI expanded
  3 = historical data UI
  4 = about page
  */
  const [expanded, setExpanded] = useState(0);

  //sync "expanded" state variable with left UI state variable set from app state in app.js
  useEffect(() => updateExpanded(leftUIState), [leftUIState]);

  //handle left UI state changes by toggling appropriate css classes
  const updateExpanded = (newExpanded) => {
    if (expanded !== newExpanded) {
      if (expanded === 1 || expanded === 3 || expanded === 4) {
        leftUIRef.current.classList.toggle("open");
      } else if (expanded === 2) {
        leftUIRef.current.classList.toggle("expanded");
      }

      if (newExpanded === 1 || newExpanded === 3 || newExpanded === 4) {
        leftUIRef.current.classList.toggle("open");
      } else if (newExpanded === 2) {
        leftUIRef.current.classList.toggle("expanded");
      }
    }

    setExpanded(newExpanded);
    newExpanded !== leftUIState && setLeftUIState(newExpanded);
  };

  //get total of property across first "numStates" states
  const propTotal = (stateStreamflow, prop, numStates = 7) => {
    let total = 0;
    stateStreamflow.forEach((state) => {
      if (state.id < numStates) {
        total += state[prop];
      }
    });
    return total;
  };

  //get total of property across first "numStates" states with normalization function "func" applied
  const funcPropTotal = (stateStreamflow, prop, propDivide, func, numStates = 7) => {
    let total = 0;
    stateStreamflow.forEach((state) => {
      if (state.id < numStates) {
        total += (state[prop] / state[propDivide]) * func(state[propDivide]);
      }
    });
    return total;
  };

  //return list item containing a slider component for each state in the state streamflow array
  /* slider components are passed their corresponding state streamflow object spread as properties
     along with the dispatch function for updating the state streamflow array and the propTotal function */
  const sliderArray = () => {
    return stateStreamflow.map((state) => (
      <li key={state.id}>
        {state.id === 0 && <h2>Upper Basin Allocation</h2>}
        {state.id === 4 && <h2>Lower Basin Allocation</h2>}
        <Slider
          id={state.id}
          name={state.name}
          postal={state.postal}
          use={state.use}
          allocation={state.allocation}
          waterRight={state.waterRight}
          //totalVolume={state.totalVolume}
          stateStreamflowDispatch={stateStreamflowDispatch}
          expanded={Boolean(expanded - 1)}
          propTotal={(prop, numStates = 7) => propTotal(stateStreamflow, prop, numStates)}
          funcPropTotal={(prop, propDivide, func, numStates = 7) =>
            funcPropTotal(stateStreamflow, prop, propDivide, func, numStates)
          }
        />
      </li>
    ));
  };

  //assembles left UI
  /* UI consists of title, slider info box with dummy slider, square UI buttons (currently temporary button),
     slider array, and total use/allocation slider */
  return (
    <div className="left-ui" ref={leftUIRef}>
      {expanded !== 0 &&
        (expanded == 3 ? (
          <DataUI stateStreamflow={stateStreamflow} />
        ) : expanded == 4 ? (
          <About />
        ) : (
          <div>
            <h1>EXPLORE MODE</h1>
            <p>Use these controls to create your own scenario.</p>
            <div className="slider-info-box">
              <Slider id={7} />
            </div>
            {expanded === 2 && (
              <div className={"scalar-slider-box"}>
                <h2 className={"scalar-text"}>Water Supply Scalar</h2>
                <div className={"scalar-slider-container"}>
                  <ReactSlider
                    className={"scalar-slider"}
                    value={Math.round(uniformScalar * 100)}
                    renderTrack={(props, state) => (
                      <div {...props}>
                        {!state.index ? (
                          <p className="track-text" style={{ left: 0 }}>
                            {state.value + "%"}
                          </p>
                        ) : (
                          <p className="track-text" style={{ left: "100%" }}>
                            {"100%"}
                          </p>
                        )}
                      </div>
                    )}
                    onChange={(value) => {
                      setUniformScalar(value / 100);
                    }}
                    onAfterChange={(value) => {
                      //Implement ecoregion feature update
                      setUniformScalar(value / 100);
                      afterSetUniformScalar();
                    }}
                  />
                  <div className="slider-info-icon-background"></div>
                  <img src="icons/i.png" alt="icon" className="slider-info-icon"></img>
                  <p className="scalar-info-text">
                    Uniform streamflow scalar:<br/>Use this slider to simulate wide-spread drought conditions relative to the 2022 water year.
                  </p>
                  <div className="scalar-use-line">
                    <p className="scalar-use-label">STREAMFLOW %</p>
                  </div>
                  <div className="scalar-max-line">
                    <p className="scalar-max-label">2022 WATER YEAR</p>
                  </div>
                </div>
              </div>
            )}
            <ul>{sliderArray()}</ul>
            <h2 style={{ position: "absolute", marginTop: "-5px" }}>Total Allocation</h2>
            <Slider
              id={8}
              use={Math.round(100 * propTotal(stateStreamflow, "use")) / 100}
              allocation={Math.round(100 * propTotal(stateStreamflow, "allocation")) / 100}
              waterRight={Math.round(100 * propTotal(stateStreamflow, "waterRight")) / 100}
            />
          </div>
        ))}
      <div className="button-panel-left">
        <MenuButton
          id={"sliderUIButton"}
          iconSrcs={["icons/drop_black.png", "icons/drop_white.png"]}
          borderCode={0}
          clicked={expanded === 1 || expanded === 2}
          onClick={() => {
            if (expanded === 0 || expanded === 3 || expanded === 4) {
              updateExpanded(1);
            } else if (expanded === 1 || expanded === 2) {
              updateExpanded(0);
            }
          }}
        />
        <div className="button-panel-gap"></div>
        <MenuButton
          id={"expandSliderUIButton"}
          iconSrcs={["icons/expand_black.png", "icons/expand_white.png"]}
          borderCode={1}
          clicked={expanded === 2}
          onClick={() => {
            if (expanded === 2) {
              updateExpanded(1);
            } else {
              updateExpanded(2);
            }
          }}
        />
        <MenuButton
          id={"dataUIButton"}
          iconSrcs={["icons/chart_black.png", "icons/chart_white.png"]}
          borderCode={2}
          clicked={expanded === 3}
          onClick={() => {
            if (expanded === 3) {
              updateExpanded(0);
            } else {
              updateExpanded(3);
            }
          }}
        />
        <MenuButton
          id={"infoButton"}
          iconSrcs={["icons/info_black.png", "icons/info_white.png"]}
          borderCode={3}
          clicked={expanded === 4}
          onClick={() => {
            {
              if (expanded === 4) {
                updateExpanded(0);
              } else {
                updateExpanded(4);
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default LeftUI;
