import { useState } from "react";
import Modal from "./Modal.js";
import ReactSlider from "react-slider";

//array storing order layer toggles should be displayed in
/* Strings here must match "name" property in layers.json */
const uIKeys = [
  "Legal",
  "Ecosystems",
  "divider",
  "State Boundaries",
  "Counties",
  "Cities",
  "Urban Areas",
  "Tribal Lands",
  "River Streamflow",
  "Colorado River",
  "Aqueducts + Canals",
  "Dams",
  "Parks and Protected Areas",
  "Basin Region",
  "Ecoregions",
  "Water Right Seniority",
];

// function for determining if value at index in array only occurs once
const isUnique = (value, index, array) => array.indexOf(value) === index;

const daysToYear = (days) => 2024 - days / 365;
const yearToDays = (year) => 365 * (2024 - year);

const seniorityMaxYears = [1845, 2024];
const seniorityGrad = ["rgb(124,247,255)", "rgb(78,8,21)"];
const seniorityGradVecs = [
  seniorityGrad[0].split("(")[1].split(")")[0].split(","),
  seniorityGrad[1].split("(")[1].split(")")[0].split(","),
];

const LayersUI = ({
  visible,
  layers,
  layersDispatch,
  layersUIRef,
  updateEcoRegionFeatures,
  minSeniority,
  setMinSeniority,
  maxSeniority,
  setMaxSeniority,
}) => {
  // categories that data layers can fall into
  const [menuCategories, setMenuCategories] = useState([
    { name: "Legal", checked: false },
    { name: "Ecosystems", checked: false },
  ]);

  const [keyHover, setKeyHover] = useState(null);

  // returns toggle-able lists of category and data layer menu items
  const buildUI = () => {
    const uIArray = [];

    // generate category menu items
    menuCategories.forEach((category) => {
      uIArray.push(
        <li key={category.name} className="layer-container">
          <img src="icons/minus_white.png" alt={category.name + " icon"} className="layer-ui-icon"></img>
          <p className="layer-ui-text" id="category-text">
            {category.name}
          </p>
          <label className="switch">
            <input
              type="checkbox"
              checked={category.checked}
              onChange={() => {
                // toggle visiblity of layers in category
                layers
                  .filter((layer) => layer.hasOwnProperty("metadata") && layer.metadata.category === category.name)
                  .forEach((layer) => {
                    layersDispatch({
                      type: "SET",
                      id: layer.id,
                      property: "layout",
                      subProperty: "visibility",
                      value: !category.checked ? "visible" : "none",
                    });
                  });

                // update category property determining whether menu toggle is on or off
                setMenuCategories(
                  menuCategories.map((obj) => (obj.name === category.name ? { ...obj, checked: !obj.checked } : obj))
                );
              }}
            />
            <span className="switch-knob"></span>
          </label>
        </li>
      );
    });

    // divider between category menu and data layer menu
    uIArray.push(<hr key={"divider"}></hr>);

    // generate data layer menu items
    layers
      .filter(
        (layer, index, layers) =>
          layer.hasOwnProperty("metadata") &&
          layer.metadata.category &&
          isUnique(
            layer.metadata.name,
            index,
            layers.map((layer) => layer.hasOwnProperty("metadata") && layer.metadata.name)
          )
      )
      .forEach((layer) => {
        const subcategory = layer.metadata.category === "Model" && !(layer.metadata.name === "River Streamflow");

        // create menu item for each unique layer name (metadata.name) in layer list
        uIArray.push(
          <li key={layer.metadata.name} className="layer-container" id={subcategory ? "layer-subcategory" : null}>
            {keyHover === layer.metadata.name && (
              <Modal
                position={{
                  transform: "translateY(-50%)",
                  top: "calc(var(--layer-ui-layer-toggle-height) / 2)",
                  right: subcategory
                    ? "calc(var(--layer-ui-width) + 2 * var(--layer-ui-padding) + var(--layer-ui-layer-toggle-height) + 0.25vh)"
                    : "calc(var(--layer-ui-width) + 2 * var(--layer-ui-padding))",
                  width: "20vw",
                }}
                arrowSide={2}
                arrowPlacement={50}
                text={
                  layer.metadata.description
                    ? layer.metadata.description
                    : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                }
                footer={
                  <div>
                    {
                      //mouseover bridge between layer UI and popup
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: "100%",
                          width: "calc(100% + 2 * var(--modal-arrow-size))",
                        }}
                      />
                    }
                    {layer.metadata.name === "Water Right Seniority" && (
                      <ReactSlider
                        className="seniority-slider"
                        thumbClassName="thumb"
                        trackClassName="track-seniority"
                        min={seniorityMaxYears[0]}
                        max={seniorityMaxYears[1]}
                        defaultValue={[daysToYear(maxSeniority), daysToYear(minSeniority)]}
                        ariaLabel={["Lower thumb", "Upper thumb"]}
                        ariaValuetext={(state) => `Thumb value ${Math.round(state.valueNow)}`}
                        renderThumb={(props, state) => {
                          const colorScalar =
                            (state.valueNow - seniorityMaxYears[0]) / (seniorityMaxYears[1] - seniorityMaxYears[0]);

                          const colorVec = [
                            seniorityGradVecs[0][0] * (1 - colorScalar) + seniorityGradVecs[1][0] * colorScalar,
                            seniorityGradVecs[0][1] * (1 - colorScalar) + seniorityGradVecs[1][1] * colorScalar,
                            seniorityGradVecs[0][2] * (1 - colorScalar) + seniorityGradVecs[1][2] * colorScalar,
                          ];

                          props.style = {
                            ...props.style,
                            backgroundColor: "rgb(" + colorVec[0] + "," + colorVec[1] + "," + colorVec[2] + ")",
                          };

                          let thumbTextVisible = true;
                          if (state.index === 0 && colorScalar < 0.15) {
                            thumbTextVisible = false;
                          } else if (state.index === 1 && colorScalar > 0.85) {
                            thumbTextVisible = false;
                          }

                          return (
                            <div {...props}>
                              {thumbTextVisible && (
                                <p className={"thumb-seniority-text-" + state.index}>{state.valueNow}</p>
                              )}
                            </div>
                          );
                        }}
                        renderTrack={(props, state) => {
                          if (state.index === 1) {
                            const leftGradScalar =
                              (state.value[0] - seniorityMaxYears[0]) / (state.value[1] - state.value[0]);
                            const rightGradScalar =
                              (seniorityMaxYears[1] - state.value[1]) / (state.value[1] - state.value[0]);

                            props.style = {
                              ...props.style,
                              backgroundImage:
                                "linear-gradient(to right, " +
                                seniorityGrad[0] +
                                " " +
                                -leftGradScalar * 100 +
                                "%, " +
                                seniorityGrad[1] +
                                " " +
                                (1 + rightGradScalar) * 100 +
                                "%",
                            };
                          }
                          return (
                            <div {...props}>
                              {state.index === 0 && <p className="track-seniority-text-0">{seniorityMaxYears[0]}</p>}
                              {state.index === 2 && <p className="track-seniority-text-1">{seniorityMaxYears[1]}</p>}
                            </div>
                          );
                        }}
                        pearling
                        minDistance={10}
                        onChange={(value) => {
                          setMinSeniority(yearToDays(value[1]));
                          setMaxSeniority(yearToDays(value[0]));
                        }}
                      />
                    )}
                  </div>
                }
              />
            )}
            <img src={layer.metadata.iconSrc} alt={layer.metadata.name + " icon"} className="layer-ui-icon"></img>
            <p
              className="layer-ui-text"
              onMouseEnter={() => {
                setKeyHover(layer.metadata.name);
              }}
            >
              {layer.metadata.name}
            </p>
            {!subcategory && (
              <label className="switch">
                <input
                  type="checkbox"
                  checked={!!(layer.layout.visibility === "visible")}
                  onChange={() => {
                    // toggle all layers with layer name metadata.name
                    layers
                      .filter(
                        (layerCheck) =>
                          layerCheck.hasOwnProperty("metadata") &&
                          (layerCheck.metadata.name === layer.metadata.name ||
                            (layerCheck.metadata.category === "Model" && layer.metadata.category === "Model"))
                      )
                      .forEach((layerCheck) => {
                        layersDispatch({
                          type: "SET",
                          id: layerCheck.id,
                          property: "layout",
                          subProperty: "visibility",
                          value: layerCheck.layout.visibility === "none" ? "visible" : "none",
                        });
                      });

                    //ensure ecoregions gradients are updated when layer is toggled on
                    if (
                      layer.metadata.name === "Ecoregions" &&
                      layers.find((layerCheck) => layerCheck.id === layer.id).layout.visibility === "none"
                    ) {
                      updateEcoRegionFeatures();
                    }
                  }}
                />
                <span className="switch-knob"></span>
              </label>
            )}
          </li>
        );
      });
    uIArray.sort((a, b) => (uIKeys.indexOf(a.key) > uIKeys.indexOf(b.key) ? 1 : -1));
    return uIArray;
  };

  if (visible) {
    // popuplate layers ui with menu items
    return (
      <div
        className="layers-ui"
        ref={layersUIRef}
        onMouseLeave={() => {
          setKeyHover(null);
        }}
      >
        <p className="layer-ui-title">Map Key | Map Layer Visibility</p>
        <ul style={{ position: "relative" }}>{buildUI()}</ul>
      </div>
    );
  } else {
    return <div className="layers-ui" ref={layersUIRef}></div>;
  }
};

LayersUI.defaultProps = { layers: [], layersDispatch: () => {} };

export default LayersUI;
