import { useRef, useEffect } from "react";

const Modal = ({ position, arrowSide, arrowPlacement, text, appStateId, hide, footer }) => {
  //get transition time from css variable
  const transitionTime = parseInt(
    window.getComputedStyle(document.documentElement).getPropertyValue("--transition-time")
  );

  //reference for toggling css syling
  const modalRef = useRef(null);

  //trigger fade-in animation on app state ID or modal text changes
  /* toggles fade-in animation class and disables class again after animation duration */
  useEffect(() => {
    if (modalRef.current && !modalRef.current.classList.contains("fade-in")) {
      modalRef.current.classList.toggle("fade-in");
      setTimeout(() => {
        if (modalRef.current && modalRef.current.classList.contains("fade-in")) {
          modalRef.current.classList.toggle("fade-in");
        }
      }, transitionTime);
    }
  }, [appStateId, text]);

  let arrowStyle = {};

  //set arrow to modal edge corresponding to arrowSide code and position on edge based on arrowPlacement value (0-1)
  /*
  0 = left
  1 = top
  2 = right
  3 = bottom
  */
  switch (arrowSide) {
    case 0:
      arrowStyle = {
        borderRightColor: "var(--ui-background-color)",
        transform: "translate(-100%, -50%)",
        top: arrowPlacement + "%",
        left: 0,
      };
      break;
    case 1:
      arrowStyle = {
        borderBottomColor: "var(--ui-background-color)",
        transform: "translate(-50%, -100%)",
        top: 0,
        left: arrowPlacement + "%",
      };
      break;
    case 2:
      arrowStyle = {
        borderLeftColor: "var(--ui-background-color)",
        transform: "translate(100%, -50%)",
        top: arrowPlacement + "%",
        right: 0,
      };
      break;
    case 3:
      arrowStyle = {
        borderTopColor: "var(--ui-background-color)",
        transform: "translate(-50%, 100%)",
        bottom: 0,
        left: arrowPlacement + "%",
      };
      break;
    default:
      arrowStyle = {
        display: "none",
      };
  }

  return (
    !hide && (
      <div className={"modal"} style={{ ...position }} ref={modalRef}>
        <div className="modal-arrow" style={arrowStyle}></div>
        <p className="modal-text">{text}</p>
        {footer}
      </div>
    )
  );
};

export default Modal;
