import { Popup } from "react-map-gl";

const PoupupBox = ({
  id,
  anchor,
  longitude,
  latitude,
  offset,
  closeButton,
  maxWidth,
  title,
  subHeader,
  body,
  footer,
}) => {
  return (
    <Popup
      className="popup"
      id={id}
      anchor={anchor}
      longitude={longitude}
      latitude={latitude}
      key={latitude + longitude}
      offset={offset}
      closeButton={closeButton}
      closeOnClick={false}
      maxWidth={maxWidth}
    >
      <h2 className="popup-title" id={id}>
        {title}
      </h2>
      <p className="popup-subheader" id={id}>
        {subHeader}
      </p>
      <div className="popup-body" id={id} style={{ display: "flex" }}>
        {body &&
          body.map((child, index) => (
            <div
              className={"popup-body-child " + "child-" + index}
              key={"popup-body-child-" + index}
              style={{ flex: child.flex }}
            >
              {child.type === "image" ? (
                <img src={child.image} alt={title} style={{ width: "100%", margin: 0 }}></img>
              ) : child.type === "text" ? (
                <p>{child.text}</p>
              ) : (
                ""
              )}
            </div>
          ))}
      </div>
      <div className="popup-footer" id={id}>
        {footer}
      </div>
    </Popup>
  );
};

export default PoupupBox;
