//Data object for streamflow graph edges.
const EdgeData = {
        "properties": {
          "iD": 0,
          "weight": 1.0,
          "color": 1.0
        },
        "type": "Feature",
        "geometry": {
          "coordinates": [
            [
              0.0,
              0.0
            ],
            [
              0.0,
              0.0
            ]
          ],
          "type": "LineString"
        }
};

export default EdgeData;