const Header = ({ appStateId, setAppState }) => {
  //app state IDs and names for each header button
  const headerAppStates = [
    { appStateId: "scenario-1-1", name: "Welcome" },
    { appStateId: "scenario-2-1", name: "Climate Change" },
    { appStateId: "scenario-3-1", name: "Deadpool" },
    { appStateId: "scenario-4-1", name: "Fill Mead First" },
    { appStateId: "scenario-5-1", name: "Explore" },
  ];

  //create button array
  const headerButtons = headerAppStates.map((appStateInfo, index) => (
    <li className="header-list-item" key={appStateInfo.appStateId}>
      {index > 0 && <div className="header-list-divider"></div>}
      <button
        className={"header-button"}
        id={
          appStateId && appStateInfo?.appStateId.slice(0, -1) === appStateId.slice(0, -1)
            ? "header-button-active"
            : null
        }
        type="button"
        onClick={() => {
          setAppState(appStateInfo.appStateId);
        }}
      >
        {appStateInfo.name}
      </button>
    </li>
  ));

  return (
    <div className="header-container">
      <img src="icons/drop_white.png" alt={"River View App"} className="header-title-icon"></img>
      <h1 className="header-title">DROUGHT INTERFACES</h1>
      <ul className="header-list">{headerButtons}</ul>
    </div>
  );
};

export default Header;
