import StreamNode from "./StreamNode";

//GeoState data object. Manages assiated nodes.
class GeoState {
  stateName = "";
  downstreamStates = [GeoState];

  baseStreamConsWeights = [Number];

  baseStreamSendTotal = 0.0;
  baseStreamCons = 0.0;
  baseStreamAllc = 0.0;
  //baseStreamVolm = 0.0;

  crntStreamCons = 0.0;
  crntStreamAllc = 0.0;
  //crntStreamVolm = 0.0;

  nodes = [StreamNode];
  dams = [StreamNode];
  recvNodes = [StreamNode];
  sendNodes = [StreamNode];

  constructor() {
    this.nodes = [];
    this.dams = [];
    this.recvNodes = [];
    this.sendNodes = [];
  }

  //add a node to this state
  addNode(allNodes = [StreamNode], node = StreamNode) {
    this.nodes.push(node);

    if (node.nodeData.properties.streamflowType === 1) this.dams.push(node);

    if (node.isRecvNode(allNodes)) this.recvNodes.push(node);

    if (node.isSendNode(allNodes)) this.sendNodes.push(node);
  }

  //initialize parameters only after all nodes are added
  init(stateName = "", downstreamStates = [GeoState], consumption = 1000000.0, allocation = 1500000.0) {
    this.stateName = stateName;
    this.downstreamStates = downstreamStates;

    this.crntStreamCons = this.baseStreamCons = consumption;
    this.crntStreamAllc = this.baseStreamAllc = allocation;
  }

  //initialize streamflow for all of this states nodes based on topology and this states measured consumption.
  initStreamflows(allNodes = [StreamNode]) {
    this.baseStreamSendTotal = 0.0;
    this.nodes.forEach((node) => {
      this.baseStreamSendTotal += node.initStreamflow(allNodes);
    });
  }

  //initialize streamflow for all of this states nodes based on topology and this states measured consumption.
  initStreamflowWeights() {
    this.nodes.forEach((node) => {
      node.initStreamflowWeight(this.stateName, this.baseStreamSendTotal);
    });
  }

  //initialize streamflow for all of this states nodes based on topology and this states measured consumption.
  initStreamflowDeltas() {
    this.crntStreamCons = this.baseStreamCons;
    this.nodes.forEach((node) => {
      node.initStreamflowDelta(this.stateName, this.baseStreamCons);
    });
    //console.log("@: " + this.stateName + ": " + this.recvNodes.length + "reception nodes, " + this.getStreamRecvTotal() + " AcFt / Yr");
  }

  //Sets state consumptive use. Returns true if consumption is less than allocation.
  setConsumptiveUse(consumptiveUse = 1.0, uniformScalar = 1.0) {
    if (consumptiveUse < 0) consumptiveUse = 0;

    this.crntStreamCons = consumptiveUse;

    //for now we adjust all nodes
    this.nodes.forEach((node) => {
      node.setStreamConsumption(this.stateName, this.crntStreamCons, uniformScalar);
    });

    return !this.getViolationFlag();
  }

  //Sets state allocation. Returns true if consumption is less than allocation.
  setAllocation(allocation = 1.0) {
    if (allocation < 0) allocation = 0;

    this.crntStreamAllc = allocation;

    return !this.getViolationFlag();
  }

  //returns total consumptive use for this state
  getConsumptiveUseTotal() {
    let sum = 0.0;
    this.nodes.forEach((node) => {
      sum += node.getWeightedConsumptiveUse(this.stateName);
    });

    return sum;
  }

  //Returns state streamflow input from other states
  getStreamRecvTotal() {
    let sum = 0.0;
    this.recvNodes.forEach((node) => {
      sum += node.crntStreamRecv;
      sum += node.baseStreamDeltaPos;
    });

    return sum;
  }

  //Returns state streamflow output to other states
  getStreamSendTotal() {
    let sum = 0.0;
    this.sendNodes.forEach((node) => {
      sum += node.crntStreamSend;
    });

    return sum;
  }

  //Returns state streamflow average across all associated nodes. Individual node streamflows are weighted by number of states sharing that node. This accounts for streamflow at state boundaires.
  getStreamSendAvg() {
    let sum = 0.0;

    this.nodes.forEach((node) => {
      sum += node.crntStreamSend / node.nodeData.properties.states.length;
    });

    const avg = sum / this.nodes.length;

    return avg;
  }

  //returns true if current state consumption outstrips allocation
  getViolationFlag() {
    return this.crntStreamCons > this.crntStreamAllc;
  }
}

export default GeoState;
